const TARGET_TYPES = {
  all: '',
  federal_state:
    'the President & U.S. Congress; State Governors & Legislatures',
  national_all: 'the President & U.S. Congress',
  congress: 'the U.S. Congress',
  senior: 'the U.S. Senate',
  junior: 'the U.S. Senate',
  senate: 'the U.S. Senate',
  house: 'the U.S. House of Representatives',
  president: 'the President',
  vice_president: 'the Vice President',
  state_all: 'State Governors & Legislatures',
  governor: 'State Governors',
  state_legislature: 'State Legislatures',
  state_upper: 'State Senates',
  state_lower: 'State Legislatures',
  dnc: 'the Democratic National Committee',
  governor_senate: 'State Governors & the U.S. Senate',
  obama: 'President Obama',
  mayor: 'Mayors',
  ceo: 'State Election Officials',
  scotus: 'the U.S. Supreme Court',
};

// These originate from Discovery, for content that is created from
// messages that are constructed with rep data from Disco.
const TARGET_TYPES_SHORT = {
  gov: 'Governor',
  gov_lt: 'Lt. Governor',
  president: 'President',
  vice_president: 'Vice President',
  scotus: 'Supreme Court of the United States Justice',
  rep: 'U.S. House Representative',
  sen: 'U.S. Senator',
  state_lower: 'State House Representative',
  state_upper: 'State Senator',
  ceo: 'Chief Election Official',
  leo: 'Local Election Official',
  state_supreme_court: 'State Supreme Court Justice',
  mayor: 'Mayor',
};

const TARGET_TYPES_SHORT_TO_LONG = {
  // There might be more.
  gov: 'governor',
  president: 'president',
  vice_president: 'vice_president',
  scotus: 'scotus',
  rep: 'house',
  sen: 'senate',
  state_lower: 'state_lower',
  state_upper: 'state_upper',
  ceo: 'ceo',
  leo: 'leo',
  state_supreme_court: 'state_supreme_court',
  mayor: 'mayor',
};

const getLongTargetTypeFromShort = (shortKey) => {
  return TARGET_TYPES_SHORT_TO_LONG[shortKey] || '';
};

const getTargetTypeHumanName = (abbreviation) => {
  const abbrev = abbreviation.toLowerCase();
  return TARGET_TYPES[abbrev] || '';
};

const getShortTargetTypeHumanName = (abbreviation, stateCode = null) => {
  const abbrev = abbreviation.toLowerCase();
  if ((stateCode === 'NE' || stateCode === 'ne') && abbrev === 'state_lower') {
    return 'State Senator';
  } else if (
    (stateCode === 'DC' || stateCode === 'dc') &&
    abbrev === 'state_lower'
  ) {
    return 'City Councilor';
  } else {
    return TARGET_TYPES_SHORT[abbrev] || '';
  }
};

const isCongressBioguide = (bioguideId) => {
  if (!bioguideId) {
    return false;
  } else if (bioguideId === 'WH000046' || bioguideId === 'VP000046') {
    return true;
  }
  const pattern = /^[A-Za-z]\d{6}$/;
  return pattern.test(bioguideId);
};

export {
  getShortTargetTypeHumanName,
  getLongTargetTypeFromShort,
  isCongressBioguide,
  TARGET_TYPES_SHORT,
};
export default getTargetTypeHumanName;

'use client';
import * as Sentry from '@sentry/nextjs';
import { useEffect, useState, useCallback } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import Modal from 'react-modal';

import Button from './button';
import Anchor from 'components/typography/anchor';
import imessage from 'images/icons/apple.svg';
import messenger from 'images/icons/messenger.svg';
import whatsapp from 'images/icons/whatsapp.svg';
import telegram from 'images/icons/telegram.svg';
import instagram from 'images/icons/instagram.svg';
import sms from 'images/icons/android.svg';
import {
  FATHOM_SIGNUP_EVENT_WHATSAPP,
  FATHOM_SIGNUP_EVENT_IMESSAGE,
  FATHOM_SIGNUP_EVENT_FB_MESSENGER,
  FATHOM_SIGNUP_EVENT_SMS,
  FATHOM_SIGNUP_EVENT_TELEGRAM,
  FATHOM_SIGNUP_EVENT_INSTA,
} from 'utilities/analytics';

import config, { CHANNEL_PARAMETER, CHANNEL_PRIORITY_ORDER } from 'siteconfig';

// This needs to be set here since it drives the rendering of the QR
const qrSize = 120;

// Preload react classes from Tailwind configuration: .ReactModal__Overlay--before-close .ReactModal__Overlay--after-open

export const CTAQRCode = ({ keyword }) => {
  return (
    <div className="mx-auto hidden rounded border border-stone-200 p-3 sm:block dark:border-stone-700">
      <QRCode
        className="dark:invert"
        size={qrSize}
        bgColor={'transparent'}
        value={`smsto:50409?&body=${encodeURIComponent(keyword)}`}
      />
    </div>
  );
};

const channelsConfig = ({ keyword, channelName }) => {
  const CONFIG = {
    apple: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.apple + keyword}
          iconSrc={imessage}
          eventName={FATHOM_SIGNUP_EVENT_IMESSAGE}
        >
          Apple Messages
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.apple + keyword}
          eventName={FATHOM_SIGNUP_EVENT_IMESSAGE}
          key={'apple'}
        >
          Apple Messages
        </Anchor>
      ),
    },
    whatsapp: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.whatsapp + keyword}
          iconSrc={whatsapp}
          eventName={FATHOM_SIGNUP_EVENT_WHATSAPP}
        >
          WhatsApp
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.whatsapp + keyword}
          eventName={FATHOM_SIGNUP_EVENT_WHATSAPP}
          key={'whatsapp'}
        >
          WhatsApp
        </Anchor>
      ),
    },
    messenger: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.messenger + keyword}
          iconSrc={messenger}
          eventName={FATHOM_SIGNUP_EVENT_FB_MESSENGER}
        >
          Messenger
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.messenger + keyword}
          eventName={FATHOM_SIGNUP_EVENT_FB_MESSENGER}
          key={'messenger'}
        >
          Messenger
        </Anchor>
      ),
    },
    telegram: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.telegram + keyword}
          iconSrc={telegram}
          size="large"
          eventName={FATHOM_SIGNUP_EVENT_TELEGRAM}
        >
          Telegram
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.telegram + keyword}
          eventName={FATHOM_SIGNUP_EVENT_TELEGRAM}
          key={'telegram'}
        >
          Telegram
        </Anchor>
      ),
    },
    instagram: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.instagram + keyword}
          iconSrc={instagram}
          size="large"
          eventName={FATHOM_SIGNUP_EVENT_INSTA}
        >
          Instagram
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.instagram + keyword}
          eventName={FATHOM_SIGNUP_EVENT_INSTA}
          key={'instagram'}
        >
          Instagram
        </Anchor>
      ),
    },
    sms: {
      button: (
        <Button
          theme="blue"
          href={config.channelUrls.sms + keyword}
          iconSrc={sms}
          eventName={FATHOM_SIGNUP_EVENT_SMS}
        >
          SMS (50409)
        </Button>
      ),
      text: (
        <Anchor
          className="anchor-text"
          href={config.channelUrls.sms + keyword}
          eventName={FATHOM_SIGNUP_EVENT_SMS}
          key={'sms'}
        >
          SMS (50409)
        </Anchor>
      ),
    },
  };

  return CONFIG[channelName];
};

// Buttons that start a Resist.bot conversation right away
const ResistCTAButtons = ({
  className,
  keyword = 'resist',
  copy = 'Get Started',
  devMode = false,
}) => {
  const [supportsAppleMessages, setSupportsAppleMessages] = useState(true);
  const [preferredChannel, setPreferredChannel] = useState(null);
  const [channelOrder, setChannelOrder] = useState(CHANNEL_PRIORITY_ORDER);
  const [selectors] = useDeviceSelectors(
    typeof window !== 'undefined' ? window?.navigator?.userAgent : 'server'
  );
  const [selectedChannel, setSelectedChannel] = useState(
    CHANNEL_PRIORITY_ORDER[0]
  );

  // Collect iOS/macOS support
  useEffect(() => {
    setSupportsAppleMessages(selectors.isIOS || selectors.isMacOs);
  }, [selectors]);

  // Collect preferred channel from URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const preferredChannel = urlParams.get(CHANNEL_PARAMETER)?.toLowerCase();

    if (preferredChannel) {
      // Do nothing if the qs param they used isn't valid or supported/
      if (CHANNEL_PRIORITY_ORDER.includes(preferredChannel)) {
        setPreferredChannel(preferredChannel);
      }
      if (preferredChannel === 'apple') {
        setSupportsAppleMessages(true);
      } else {
        setSupportsAppleMessages(false);
      }
    }
  }, []);

  // Modals

  const [navIsOpen, setNavOpen] = useState(false);

  const openModal = () => {
    setNavOpen(true);
  };

  const closeModal = () => {
    setNavOpen(false);
  };

  // Combine supportsAppleMessages and preferredChannel into a final "selectedChannel"
  useEffect(() => {
    if (preferredChannel) {
      setSelectedChannel(preferredChannel);
    } else {
      setSelectedChannel(supportsAppleMessages ? 'apple' : 'whatsapp');
    }
  }, [preferredChannel, supportsAppleMessages]);

  // An effect that combines supportsAppleMessages and preferredChannel into a final "channelOrder"
  // if preferredChannel is set, then it is moved to the front of the list
  // if not supportsAppleMessages, then apple is removed from the list
  // if both preferredChannel and supportsAppleMessages are set, preferredChannel is moved to the front of the list, apple is second
  useEffect(() => {
    const newChannelOrder = [...CHANNEL_PRIORITY_ORDER];
    if (preferredChannel) {
      newChannelOrder.splice(newChannelOrder.indexOf(preferredChannel), 1);
      newChannelOrder.unshift(preferredChannel);
    }
    if (!supportsAppleMessages) {
      newChannelOrder.splice(newChannelOrder.indexOf('apple'), 1);
    }
    setChannelOrder(newChannelOrder);
  }, [preferredChannel, supportsAppleMessages]);

  return (
    <div className={className}>
      {devMode ? (
        <>
          <p>
            Preferred Channel: {preferredChannel ? preferredChannel : 'unset'}
          </p>
          <p>Apple Support: {supportsAppleMessages ? 'yes' : 'no'}</p>
          <p>Selected Channel: {selectedChannel ? selectedChannel : 'unset'}</p>
          <p>Channel Order: {channelOrder.join(', ')}</p>
        </>
      ) : null}

      <Button theme="blue" size="large" onClick={openModal}>
        {copy}
      </Button>
      <Modal
        isOpen={navIsOpen}
        onRequestClose={closeModal}
        contentLabel="Call to Action Modal"
        className="mx-auto mt-[33vh] flex w-96 flex-col flex-nowrap gap-2 rounded-lg bg-white p-8 text-center shadow-2xl backdrop-blur dark:bg-stone-800/70"
        overlayClassName="fixed inset-0 bg-gradient-to-tr from-purple-800/80 to-fuchsia-900/80 transition-opacity duration-300 z-10"
      >
        <h2>Text {keyword} to Resistbot&nbsp;on</h2>

        {channelsConfig({ keyword, channelName: selectedChannel }).button}
        <p>
          <span>Or </span>

          {preferredChannel ? (
            <button
              className="anchor-text"
              onClick={() => {
                setPreferredChannel(null);
              }}
            >
              see all apps
            </button>
          ) : (
            channelOrder
              .slice(1)
              .map(
                (channel) =>
                  channelsConfig({ keyword, channelName: channel }).text
              )
              .reduce((prev, curr) => [prev, ' / ', curr])
          )}
        </p>
        <CTAQRCode keyword={keyword} />
      </Modal>
    </div>
  );
};

CTAQRCode.propTypes = {
  keyword: PropTypes.string.isRequired,
};

ResistCTAButtons.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.string,
  keyword: PropTypes.string,
  theme: PropTypes.string,
};

// This component handles the dev mode key handler.
// No reason this couldn't be an app level hook eventually.
export const ResistCTAButtonsWrapper = ({ className, keyword, copy }) => {
  const [devMode, setDevMode] = useState(false);
  const keyHandler = useCallback((e) => {
    // option + d (dev mode!)
    if (e.key === '∂') {
      if (e.repeat) {
        return;
      }
      setDevMode((d) => !d);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', () => {});
    };
  }, [keyHandler]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <Anchor
          href={config.channelUrls.sms + keyword}
          eventName={FATHOM_SIGNUP_EVENT_SMS}
          key={'sms'}
        >
          SMS (50409)
        </Anchor>
      }
    >
      <ResistCTAButtons
        className={className}
        keyword={keyword}
        copy={copy}
        devMode={devMode}
      />
    </Sentry.ErrorBoundary>
  );
};

ResistCTAButtonsWrapper.propTypes = {
  className: PropTypes.string,
  keyword: PropTypes.string,
};

export default ResistCTAButtonsWrapper;
